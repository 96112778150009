import React, { FC, ReactNode, useState } from 'react';
import SwiperCore, { A11y, Navigation, SwiperOptions } from 'swiper';
import { Swiper } from 'swiper/react';
import SwiperNavigation from '../swiper-navigation/swiper-navigation';

SwiperCore.use([A11y, Navigation]);

interface Props {
  readonly breakpoints: {
    [ratio: string]: SwiperOptions;
    [width: number]: SwiperOptions;
  };
  readonly children: ReactNode;
  readonly heading: string;
  readonly numberOfSlides: number;
  readonly uniqueId: string;
}

const Carousel: FC<Props> = ({
  breakpoints,
  children,
  heading,
  numberOfSlides,
  uniqueId,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const [slidesPerGroup, setSlidesPerGroup] = useState<number>();
  const show = numberOfSlides > slidesPerGroup;

  return (
    <>
      <div
        className={
          `${heading ? 'lg:justify-between' : 'lg:justify-end'} ` +
          'flex justify-center mb-12'
        }
      >
        {heading && (
          <h3 className="page-heading-three mr-4 text-center lg:text-left">
            {heading}
          </h3>
        )}

        <SwiperNavigation
          activeIndex={activeIndex}
          className={`${show ? 'hidden lg:flex' : 'hidden'}`}
          numberOfSlides={numberOfSlides}
          slidesPerGroup={slidesPerGroup}
          uniqueId={uniqueId}
        />
      </div>

      <Swiper
        a11y={{ enabled: true }}
        breakpoints={breakpoints}
        className="mobile-overhang"
        navigation={{
          nextEl: `.custom-swiper-navigation-next-${uniqueId}`,
          prevEl: `.custom-swiper-navigation-prev-${uniqueId}`,
        }}
        observeParents
        observer
        onInit={(swiper) =>
          setSlidesPerGroup(
            breakpoints[swiper.currentBreakpoint].slidesPerGroup
          )
        }
        onRealIndexChange={(swiper) => {
          const slides = breakpoints[swiper.currentBreakpoint].slidesPerGroup;

          setActiveIndex(Math.ceil((swiper.realIndex + slides) / slides));
        }}
        onResize={(swiper) =>
          setSlidesPerGroup(
            breakpoints[swiper.currentBreakpoint].slidesPerGroup
          )
        }
        spaceBetween={20}
        speed={500}
        watchSlidesVisibility
      >
        {children}
      </Swiper>

      <SwiperNavigation
        activeIndex={activeIndex}
        className={`${show ? 'flex lg:hidden' : 'hidden'} mx-auto mt-12`}
        numberOfSlides={numberOfSlides}
        slidesPerGroup={slidesPerGroup}
        uniqueId={uniqueId}
      />
    </>
  );
};

export default Carousel;
