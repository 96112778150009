import React, { FC } from 'react';
import IcomoonIconButton from '../../atoms/icomoon-icon-button/icomoon-icon-button';

interface Props {
  readonly activeIndex: number;
  readonly className?: string;
  readonly loop?: boolean;
  readonly numberOfSlides: number;
  readonly slidesPerGroup?: number;
  readonly uniqueId: string;
}

const SwiperNavigation: FC<Props> = ({
  activeIndex,
  className,
  loop,
  numberOfSlides,
  slidesPerGroup,
  uniqueId,
}: Props) => {
  const totalPages = Math.ceil(numberOfSlides / slidesPerGroup);
  const isPrevDisabled = activeIndex === 1 && !loop;
  const isNextDisabled = activeIndex === totalPages && !loop;

  return (
    <div className={`${className} flex items-center justify-between w-56`}>
      <div
        className={
          `${isPrevDisabled ? 'border-grey-200' : 'border-french-blue'} ` +
          'border-3 rounded-full'
        }
      >
        <IcomoonIconButton
          className={`custom-swiper-navigation-prev-${uniqueId} m-2`}
          color={isPrevDisabled ? 'grey-200' : 'black'}
          disabled={isPrevDisabled}
          name="chevron-left"
          size="lg"
        />
      </div>

      <span className="mx-auto">
        {activeIndex} of {totalPages}
      </span>

      <div
        className={
          `${isNextDisabled ? 'border-grey-200' : 'border-french-blue'} ` +
          'border-3 rounded-full'
        }
      >
        <IcomoonIconButton
          className={`custom-swiper-navigation-next-${uniqueId} m-2`}
          color={isNextDisabled ? 'grey-200' : 'black'}
          disabled={isNextDisabled}
          name="chevron-right"
          size="lg"
        />
      </div>
    </div>
  );
};

SwiperNavigation.defaultProps = {
  className: '',
  loop: false,
  slidesPerGroup: 1,
};

export default SwiperNavigation;
